export const AUTH_USER = '[AUTH] AUTH_USER';
export const AUTH_CHECK_TIMEOUT = '[AUTH] AUTH_CHECK_TIMEOUT';
export const AUTH_START = '[AUTH] AUTH_START';
export const AUTH_SUCCESS = '[AUTH] AUTH_SUCCESS';
export const AUTH_FAIL = '[AUTH] AUTH_FAIL';
export const AUTH_LOGOUT = '[AUTH] AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = '[AUTH] SET_AUTH_REDIRECT_PATH';
export const AUTH_MODAL_TOGGLE = '[AUTH] AUTH_MODAL_TOGGLE'
export const AUTH_INITIATE_LOGOUT = '[AUTH] AUTH_INITIATE_LOGOUT'
export const AUTH_CHECK_STATE = '[AUTH] AUTH_CHECK_STATE'
export const AGB_CLICK = '[AUTH] AGB_CLICK'
export const REGISTER_USER = '[AUTH] REGISTER_USER'
export const RESET_PASWORD_FAIL = '[AUTH] RESET_PASWORD_FAIL'
export const RESET_PASSWORD_START = '[AUTH] RESET_PASSWORD_START'
export const RESET_PASSWORD = '[AUTH] RESET_PASSWORD'
export const NEW_PASSWORD_MODAL_TOGGLE = '[AUTH] NEW_PASSWORD_MODAL_TOGGLE'
export const SET_NEW_PASSWORD = '[AUTH] SET_NEW_PASSWORD'
export const SET_NEW_PASSWORD_START = '[AUTH] SET_NEW_PASSWORD_START'
export const RESET_PASSWORD_MODAL_TOGGLE = '[AUTH] RESET_PASSWORD_MODAL_TOGGLE'
export const RESET_PASSWORD_MODAL_SHOW = '[AUTH] RESET_PASSWORD_MODAL_SHOW'
export const SEND_PASSWORD_RESET = '[AUTH] SEND_PASSWORD_RESET'
export const USER_UPDATED = '[AUTH] USER_UPDATED'

export const ADD_TO_SHOPPING_CART = 'ADD_TO_SHOPPING_CART'
export const SHOW_CONFIGURATION_MODAL = 'SHOW_CONFIGURATION_MODAL'
export const ADD_TO_SHOPPING_CART_AND_GO_TO_CART = 'ADD_TO_SHOPPING_CART_AND_GO_TO_CART'
export const REMOVE_FROM_SHOPPING_CART = 'REMOVE_FROM_SHOPPING_CART'
export const REMOVE_TEMP_FROM_SHOPPING_CART = 'REMOVE_TEMP_FROM_SHOPPING_CART'
export const REMOVE_FROM_SHOPPING_CART_AND_BACK_TO_PRODUCTS = 'REMOVE_FROM_SHOPPING_CART_AND_BACK_TO_PRODUCTS'
export const APPLY_CODE = '[SHOPPING CART] APPLY_PROMO_CODE'
export const APPLY_CODE_SUCCESS = '[SHOPPING CART] APPLY_PROMO_CODE_SUCCESS'
export const DELETE_CODE = '[SHOPPING CART] DELETE_APPLY_PROMO_CODE'

export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCTS_START = 'GET_PRODUCTS_START'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL'
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS'

export const VARIANT_CHECK = 'VARIANT_CHECK'
export const NO_LAUNDRY_CHECK = 'NO_LAUNDRY_CHECK'
export const WASHING_AND_IRONING_CHECK = 'WASHING_AND_IRONING_CHECK'
export const WASHING_AND_IRONING_SPEEDY_CHECK = 'WASHING_AND_IRONING_SPEEDY_CHECK'
export const BUTTON_CHECK = 'BUTTON_CHECK'
export const HEM_CHECK = 'HEM_CHECK'
export const ZIPPER_CHECK = 'ZIPPER_CHECK'
export const QUANTITY_ADD = 'QUANTITY_ADD'
export const QUANTITY_REMOVE = 'QUANTITY_REMOVE'
export const DELIVERY_CHECK = 'DELIVERY_CHECK'
export const LEATHER_TYPE_CHECK = 'LEATHER_TYPE_CHECK'
export const ADDITIONAL_CHECK = 'ADDITIONAL_CHECK';
export const CONFIRM_PICKUP_LOCATION = "CONFIRM_PICKUP_LOCATION";
export const CONFIRM_DELIVERY_LOCATION = "CONFIRM_DELIVERY_LOCATION";
export const REMOVE_ALL_PRODUCTS_FROM_SHOPPING_CART = "REMOVE_ALL_PRODUCTS_FROM_SHOPPING_CART"
export const CREATE_PAYENGINE_ORDER_INTENT = "CREATE_PAYENGINE_ORDER_INTENT";
export const CONFIRM_WITHOUT_ADDRESS = "[SHOPPING CART] CONFIRM_WITHOUT_ADDRESS"
export const CHANGE_NUMBER_OF_DELIVERIES = "[SHOPPING CART] CHANGE_NUMBER_OF_DELIVERIES"
export const NOTES_CHANGE = "[SHOPPING CART] NOTES_CHANGE"
export const GIFT_CARD_ITEM_SUBMIT = "[SHOPPING CART] GIFT_CARD_ITEM_SUBMIT"


export const TOGGLE_SIDEBAR_MODAL = 'TOGGLE_SIDEBAR_MODAL';

export const GET_ADDRESSES = '[ADDRESSES] GET_ADDRESSES'
export const GET_ADDRESSES_START = '[ADDRESSES] GET_ADDRESSES_START'
export const GET_ADDRESSES_SUCCESS = '[ADDRESSES] GET_ADDRESSES_SUCCESS'
export const GET_ADDRESSES_FAIL = '[ADDRESSES] GET_ADDRESSES_FAIL'
export const ADDRESS_MODAL_TOGGLE = '[ADDRESSES] ADDRESS_MODAL_TOGGLE'
export const ADDRESS_SUBMIT = '[ADDRESSES] ADDRESS_SUBMIT'
export const ADDRESS_DELETE = '[ADDRESSES] ADDRESS_DELETE'

export const GET_BONUSES = '[BONUSES] GET_BONUSES'
export const GET_BONUSES_SUCCESS = '[BONUSES] GET_BONUSES_SUCCESS'
export const GET_BONUSES_START = '[BONUSES] GET_BONUSES_START'
export const GET_BONUSES_FAIL = '[BONUSES] GET_BONUSES_FAIL'

export const SUBMIT_ORDER_GO_TO_DETAILS = '[ORDERS] SUBMIT_ORDER_GO_TO_DETAILS'
export const SUBMIT_ORDER = '[ORDERS] SUBMIT_ORDER'
export const GET_ORDERS = '[ORDERS] GET_ORDERS'
export const GET_ORDERS_START = '[ORDERS] GET_ORDERS_START'
export const GET_ORDERS_SUCCESS = '[ORDERS] GET_ORDERS_SUCCESS'
export const GET_ORDERS_FAIL = '[ORDERS] GET_ORDERS_FAIL'
export const GET_ADMIN_ORDERS = '[ORDERS] GET_ADMIN_ORDERS'
export const GET_ADMIN_ORDER_DETAILS = '[ORDERS] GET_ADMIN_ORDER_DETAILS'
export const GET_ORDER_DETAILS = '[ORDERS] GET_ORDER_DETAILS'
export const GET_ORDER_DETAILS_SUCCESS = '[ORDERS] GET_ORDER_DETAILS_SUCCESS'
export const GET_ORDER_DETAILS_FAIL = '[ORDERS] GET_ORDER_DETAILS_FAIL'
export const ORDER_STATUS_CHANGE = '[ORDERS] ORDER_STATUS_CHANGE'
export const ORDER_STATUS_CHANGE_START = '[ORDER] ORDER_STATUS_CHANGE_START'
export const ORDER_STATUS_CHANGE_FAIL = '[ORDERS] ORDER_STATUS_CHANGE_FAIL'
export const ORDER_STATUS_CHANGE_SUCCESS = '[ORDERS] ORDER_STATUS_CHANGE_SUCCESS'
export const TOGGLE_EXPRESS = '[ORDERS] TOGGLE_EXPRESS'



export const GET_USER_DETAILS_FAIL = '[USERS] GET_USER_DETAILS_FAIL'
export const GET_USER_DETAILS_SUCCESS = '[USERS] GET_USER_DETAILS_SUCCESS'
export const GET_USER_DETAILS = '[USERS] GET_USER_DETAILS'
export const GET_ADMIN_USERS = '[USERS] GET_ADMIN_USERS'
export const GET_USERS_SUCCESS = '[USERS] GET_USERS_SUCCESS'
export const GET_USERS_FAIL = '[USERS] GET_USERS_FAIL'
export const GET_USERS_START = '[USERS] GET_USERS_START'
export const GET_USERS = '[USERS] GET_USERS'
export const GET_USER_START = '[USERS] GET_USER_START'

export const SELECT_CATEGORY = '[CATEGORIES] SELECT_CATEGORY'