import React, { Component } from 'react'
import { ListGroup, ListGroupItem, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap'
import { connect } from 'react-redux'
import { formatNumber } from '../../Utils'

import QuantityField from './QuantityField'
import * as actions from '../../store/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

class TailoringItem extends Component {

    render() {

        const {
            button,
            buttonSelected,
            buttonPrice,
            buttonQuantity,
            hem,
            hemSelected,
            hemQuantity,
            hemPrice,
            zipper,
            zipperSelected,
            zipperQuantity,
            zipperPrice
        } = this.props

        const {
            OnButtonCheck,
            OnHemCheck,
            OnZipperCheck,
            OnQuantityAdd,
            OnQuantityRemove,
            itemIndex
        } = this.props


        const idNoWash = `idNoWash${itemIndex}`;

        return (
            <ListGroup flush className="shopping-item-section">
                <ListGroupItem className="item-main-header">
                    <strong>Schneiderei</strong>
                    <FontAwesomeIcon className="ml-1" style={{ color: "#2a4a91" }} icon={faInfoCircle} id={idNoWash} />
                    <UncontrolledPopover trigger="hover" placement="left" target={idNoWash}>
                        <PopoverHeader>Schneiderei</PopoverHeader>
                        <PopoverBody>Wir bieten dir hochpräzise Schneiderarbeiten an. Kürzen, Reissverschluss und Knopfersatz.</PopoverBody>
                    </UncontrolledPopover>
                </ListGroupItem>
                {
                    button &&
                    <ListGroupItem className="item-button" active={buttonSelected}
                        onClick={() => OnButtonCheck(itemIndex)}>
                        <div className="d-flex">
                            <div className="item-header">Knopf</div>
                            <div className="small-price">
                                {formatNumber(buttonPrice)}
                            </div>
                        </div>
                        {
                            buttonSelected &&
                            <div className="d-flex pt-1">
                                <div className="ml-auto">
                                    <QuantityField
                                        quantity={buttonQuantity}
                                        add={() => OnQuantityAdd(itemIndex, "button")}
                                        remove={() => OnQuantityRemove(itemIndex, "button")} />
                                </div>
                            </div>
                        }
                    </ListGroupItem>
                }
                {
                    hem &&
                    <ListGroupItem className="item-button" active={hemSelected}
                        onClick={() => OnHemCheck(itemIndex)}>
                        <div className="d-flex item-header-font">
                            <div className="item-header">Kürzen</div>
                            <div className="small-price">
                                {formatNumber(hemPrice)}
                            </div>
                        </div>
                        {
                            hemSelected &&
                            <div className="d-flex pt-1">
                                <div className="ml-auto">
                                    <QuantityField
                                        quantity={hemQuantity}
                                        add={() => OnQuantityAdd(itemIndex, "hem")}
                                        remove={() => OnQuantityRemove(itemIndex, "hem")} />
                                </div>
                            </div>
                        }
                    </ListGroupItem>
                }
                {
                    zipper &&
                    <ListGroupItem className="item-button" active={zipperSelected}
                        onClick={() => OnZipperCheck(itemIndex)}>
                        <div className="d-flex item-header-font">
                            <div className="item-header">Reissverschluss</div>
                            <div className="small-price">
                                {formatNumber(zipperPrice)}
                            </div>
                        </div>
                        {
                            zipperSelected &&
                            <div className="d-flex pt-1">
                                <div className="ml-auto">
                                    <QuantityField
                                        quantity={zipperQuantity}
                                        add={() => OnQuantityAdd(itemIndex, "zipper")}
                                        remove={() => OnQuantityRemove(itemIndex, "zipper")} />
                                </div>
                            </div>
                        }
                    </ListGroupItem>
                }
            </ListGroup>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        OnButtonCheck: (itemIndex) => dispatch(actions.buttonCheck(itemIndex)),
        OnHemCheck: (itemIndex) => dispatch(actions.hemCheck(itemIndex)),
        OnZipperCheck: (itemIndex) => dispatch(actions.zipperCheck(itemIndex)),
        OnQuantityAdd: (itemIndex, itemType) => dispatch(actions.quantityAdd(itemIndex, itemType)),
        OnQuantityRemove: (itemIndex, itemType) => dispatch(actions.quantityRemove(itemIndex, itemType))
    };
};

export default connect(null, mapDispatchToProps)(TailoringItem)